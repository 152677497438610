function getPageHeight() {
    var body = document.body,
        html = document.documentElement;

    return Math.max( body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight );
}

function getCurrentScrollPostion() {
    return document.documentElement.scrollTop || document.body.scrollTop;
}

