var Question = function (identifier) {
    this.identifier = identifier;

    this.isVisible = function isVisible(answers) {
        var visible = true;

        var dependencies = this.getDependencies();
        if (dependencies != null) {
            for (index in dependencies) {
                var dependency = dependencies[index];
                if (dependency.identifier in answers) {
                    var answerDepended = answers[dependency.identifier];
                } else {
                    var answerDepended = null;
                }
                if (!this.isDependencyMet(dependency, answerDepended)) {
                    visible = false;
                }
            }
        }

        return visible;
    };

    this.isDependencyMet = function (dependency, value) {
        return (dependency.dependendValue == value);
    };

    this.getDependencies = function () {
        if (coco.dependencies[this.identifier]) {
            return coco.dependencies[this.identifier];
        }
        return null;
    }
};

var QuestionCollection = function () {

    this.getAnswers = function () {

        var tmpAnswers = $('#questionnaire_form').serializeArray();
        var answers = [];
        tmpAnswers.forEach(function (answer) {
            answers[answer.name] = answer.value;
        });
        return answers;
    };
    this.updateVisibility = function () {
        var answers = this.getAnswers();
        $('.question').each(function (index, q) {
            q = $(q);
            var identifier = q.attr('id');
            var escapedIdentifier = identifier.replace(/(:|\.|\[|\]|,)/g, "\\$1");
            question = new Question(identifier.replace("question_", ""));
            if (question.isVisible(answers)) {
                $('#' + escapedIdentifier).removeClass('question_hidden');
            } else {
                $('#' + escapedIdentifier).addClass('question_hidden');
            }
        });
        var height = $(window).height();
        if (typeof activeModal != typeof undefined) {
            activeModal.find(".modal-body").css({"max-height": height - 160 + 'px', "overflow-y": "auto"});
            var top = (height - activeModal.height() + 10) / 2;
            activeModal.css({"margin-top": "0", "top": top + 'px'});
        }
    }
};
collection = new QuestionCollection();

function updateValue(elem) {
    elem.parents('label').find('input[type="radio"]').val(elem.val());
}

$(document).on('change', '.selectOther', function(){
    $(this).parents('.form-group').find('.otherInput').prop('disabled', false);
});

$(document).on('change', '.selectOption', function(){
    $(this).parents('.form-group').find('.otherInput').prop('disabled', true);
});